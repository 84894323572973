import { isDefined, isString } from './utils';
/**
 * Converts a string value to dash-case.
 * Ex. someTestValue => some-test-value
 * @param {string} value The string to convert
 */
export function dashify(value) {
    if (!isDefined(value) || !isString(value)) {
        return value;
    }
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
/**
 * Left pads a string to the specified length with the specified character.
 * @param {string} value The string to pad.
 * @param {number} length The minimum total length.
 * @param {string} char The character to pad with, space will be used if not specified.
 */
export function padLeft(value, length, char) {
    if (!length || !isDefined(value) || !isString(value)) {
        return value;
    }
    return new Array(length - value.length + 1).join(char || ' ') + value;
}
/**
 * Right pads a string to the specified length with the specified character.
 * @param {string} value The string to pad.
 * @param {number} length The minimum total length.
 * @param {string} char The character to pad with, space will be used if not specified.
 */
export function padRight(value, length, char) {
    if (!length || !isDefined(value) || !isString(value)) {
        return value;
    }
    return value + new Array(length - value.length + 1).join(char || ' ');
}
