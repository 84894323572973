import { deepQuerySelectorAll } from 'shadow-utils';
import { isArray } from './utils';
/**
 * Holds regular expressions
 */
/* tslint:disable-next-line:require-private-underscore */
var REGULAR_EXPRESSIONS = {
    placement: {
        auto: /\s?auto?\s?/i,
        primary: /^(top|bottom|left|right)$/,
        secondary: /^(top|bottom|left|right|center)$/,
        topBottom: /^(top|bottom)$/
    },
    overflow: /(auto|scroll)/
};
/**
 * Holds the browser scrollbar width.
 */
/* tslint:disable-next-line:require-private-underscore */
var SCROLLBAR_WIDTH;
/**
 * Gets the ownerDocument for an element, if null, than returns the document element.
 * @param {Element} element The element to get the ownerDocument for
 * @returns {Document}
 */
function _ownerDocument(element) {
    return element.ownerDocument || document;
}
/**
 * Retrieves an element based on the provided root and selector.
 * @param {Element} root The root element to search within.
 * @param {string} selector The selector for the child element.
 * @param {boolean} [allowNull=false] Should the method allow the element to be not found? Default is false.
 * @returns {HTMLElement}
 */
export function getElement(root, selector, allowNull) {
    if (allowNull === void 0) { allowNull = false; }
    var element = root.querySelector(selector);
    if (!element && !allowNull) {
        throw new Error("Element not found with selector: " + selector);
    }
    return element;
}
/**
 * Checks if an element is a valid element.
 * @param {Element} element The node to test
 * @returns {boolean}
 */
export function isElement(element) {
    return element && element.nodeType === 1;
}
/**
 * Checks if an element is statically positioned.
 * @param {Element} element The node to test.
 * @returns {boolean}
 */
export function isPositionStatic(element) {
    return (window.getComputedStyle(element).position || 'static') === 'static';
}
/**
 * Parses a style string to a numeric value (removes 'px').
 * @param {string} value The style string to parse.
 * @returns {number}
 */
export function parseStyle(value) {
    if (!value || !value.length) {
        return 0;
    }
    var parsedValue = parseFloat(value);
    return isFinite(parsedValue) ? parsedValue : 0;
}
/**
 * Parses a string into a placemnt object.
 * @param {string} placement The placement string.
 * @returns {IElementPlacement}
 */
export function parsePlacement(placement) {
    var auto = REGULAR_EXPRESSIONS.placement.auto.test(placement);
    if (auto) {
        placement = placement.replace(REGULAR_EXPRESSIONS.placement.auto, '');
    }
    var placements = placement.split('-');
    placements[0] = placements[0] || 'bottom';
    if (!REGULAR_EXPRESSIONS.placement.primary.test(placements[0])) {
        placements[0] = 'bottom';
    }
    placements[1] = placements[1] || 'center';
    if (!REGULAR_EXPRESSIONS.placement.secondary.test(placements[1])) {
        placements[1] = 'center';
    }
    return {
        primary: placements[0],
        secondary: placements[1],
        auto: auto
    };
}
/**
 * Gets the index of an element in the parent element children.
 * @param {Element} element The element to get the index on.
 * @returns {number}
 */
export function elementIndex(element) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - elementIndex: invalid element argument');
    }
    if (!element.parentElement) {
        return -1;
    }
    return Array.from(element.parentElement.children).indexOf(element);
}
/**
 * Gets an array of parent elements up to the body element.
 * @param {Element} element The element to get the parents of.
 * @param {Element=} untilElement Optional element where traversal should stop.
 * @returns {Array}
 */
export function elementParents(element, untilElement) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - elementParents: invalid element argument');
    }
    var parentElements = [];
    while (element.parentElement) {
        parentElements.push(element.parentElement);
        if (element.parentElement === untilElement || element.parentElement === _ownerDocument(element).body) {
            break;
        }
        // pierce shadow DOM
        if (element.parentElement && element.parentElement.parentNode && element.parentElement.parentNode.nodeType === 11) {
            element = element.parentElement.parentNode.host;
        }
        else {
            element = element.parentElement;
        }
    }
    return parentElements;
}
/**
 * Gets the non-statically positioned parent of an element.
 * @param element The element to get the offset parent of.
 * @returns {Element}
 */
export function offsetParent(element) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - offsetParent: invalid element argument');
    }
    var offsetParentElem = element.offsetParent;
    while (offsetParentElem && isPositionStatic(offsetParentElem)) {
        offsetParentElem = offsetParentElem.offsetParent;
    }
    return offsetParentElem || _ownerDocument(element).documentElement;
}
/**
 * Gets the browser scrollbar width.
 * @returns {number}
 */
export function scrollbarWidth() {
    if (SCROLLBAR_WIDTH === undefined) {
        var elem = document.createElement('div');
        elem.style.position = 'absolute';
        elem.style.top = '-100px';
        elem.style.left = '-100px';
        elem.style.width = '50px';
        elem.style.height = '50px';
        elem.style.overflow = 'scroll';
        document.body.appendChild(elem);
        var width = elem.offsetWidth - elem.clientWidth;
        removeElement(elem);
        SCROLLBAR_WIDTH = isFinite(width) ? width : 0;
    }
    return SCROLLBAR_WIDTH;
}
/**
 * Checks if an element is scrollable.
 * @param {Element} element The element to test for scrollability
 * @returns {boolean}
 */
export function isScrollable(element) {
    var elemStyle = window.getComputedStyle(element);
    return REGULAR_EXPRESSIONS.overflow.test('' + elemStyle.overflow + elemStyle.overflowY + elemStyle.overflowX);
}
/**
 * Gets the scroll parent of an element.
 * @param {Element} element The element to get the scroll parent of.
 * @param {boolean} [includeSelf=false] Should the element be checked for scrollability.
 * @returns {Element}
 */
export function scrollParent(element, includeSelf) {
    if (includeSelf === void 0) { includeSelf = false; }
    if (!isElement(element)) {
        throw new Error('DOMUtils - scrollParent: invalid element argument');
    }
    var docElem = _ownerDocument(element).documentElement;
    var elemStyle = window.getComputedStyle(element);
    if (includeSelf && REGULAR_EXPRESSIONS.overflow.test('' + elemStyle.overflow + elemStyle.overflowY + elemStyle.overflowX)) {
        return element;
    }
    var excludeStatic = elemStyle.position === 'absolute';
    var scrollParentElem = element.parentElement || docElem;
    if (scrollParentElem === docElem || elemStyle.position === 'fixed') {
        return scrollParentElem;
    }
    while (scrollParentElem && scrollParentElem !== docElem) {
        var scrollParentStyle = window.getComputedStyle(scrollParentElem);
        if (excludeStatic && scrollParentStyle.position !== 'static') {
            excludeStatic = false;
        }
        if (!excludeStatic && REGULAR_EXPRESSIONS.overflow.test('' + scrollParentStyle.overflow + scrollParentStyle.overflowY + scrollParentStyle.overflowX)) {
            break;
        }
        scrollParentElem = scrollParentElem.scrollParent;
    }
    return scrollParentElem || docElem;
}
/**
 * Checks if the elements scroll parent scrollbars are visible.
 * @param {Element} element The element to check the scroll parent of.
 * @returns {IScrollbarVisibility}
 */
export function isScrollbarVisible(element) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - isDocumentScrolled: invalid element argument');
    }
    var scrollParentElem = scrollParent(element);
    return {
        x: scrollParentElem.scrollWidth > scrollParentElem.clientWidth,
        y: scrollParentElem.scrollHeight > scrollParentElem.clientHeight
    };
}
/**
 * Gets the offset from the element to the parent element edges.
 * If no parentElement is supplied, the documentElement will be used.
 * @param {Element} element The element to compute the offset for.
 * @param {Element=} parentElement Optional parent element to measure from.
 * @returns {ClientRect}
 */
export function offset(element, parentElement) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - offset: invalid element argument');
    }
    var elemBCR = element.getBoundingClientRect();
    var win = _ownerDocument(element).defaultView;
    var docElem = parentElement || win.document.documentElement;
    var offsetValues = { width: elemBCR.width, height: elemBCR.width, top: 0, left: 0, bottom: 0, right: 0 };
    if (!parentElement || docElem === win.document.documentElement || docElem === win.document.body) {
        offsetValues.top = win.pageYOffset + elemBCR.top;
        offsetValues.bottom = docElem.clientHeight - win.pageYOffset - elemBCR.bottom;
        offsetValues.left = win.pageXOffset + elemBCR.left;
        offsetValues.right = docElem.clientWidth - win.pageXOffset - elemBCR.right;
    }
    else {
        if (!isElement(parentElement)) {
            throw new Error('DOMUtils - offset: invalid parentElement argument');
        }
        var parentBCR = parentElement.getBoundingClientRect();
        offsetValues.top = elemBCR.top - parentBCR.top;
        offsetValues.bottom = parentBCR.bottom - elemBCR.bottom;
        offsetValues.left = elemBCR.left - parentBCR.left;
        offsetValues.right = parentBCR.right - elemBCR.right;
    }
    return {
        width: Math.round(elemBCR.width),
        height: Math.round(elemBCR.height),
        top: Math.round(offsetValues.top),
        bottom: Math.round(offsetValues.bottom),
        left: Math.round(offsetValues.left),
        right: Math.round(offsetValues.right)
    };
}
/**
 * Gets the offset from the element to the parent element viewable edges.
 * If no parentElement is supplied, the documentElement will be used.
 * @param {Element} element The element to measure
 * @param {Element=} parentElement The parent element to measure to.
 * @returns {ClientRect}
 */
export function viewportOffset(element, parentElement) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - offset: invalid element argument');
    }
    var win = _ownerDocument(element).defaultView;
    parentElement = parentElement || win.document.documentElement;
    var offsetValues = offset(element, parentElement);
    if (parentElement === win.document.documentElement) {
        offsetValues.top -= win.pageYOffset;
        offsetValues.left -= win.pageXOffset;
    }
    else {
        var parentStyle = window.getComputedStyle(parentElement);
        offsetValues.top -= parseStyle('' + parentStyle.borderTopWidth);
        offsetValues.left -= parseStyle('' + parentStyle.borderLeftWidth);
    }
    offsetValues.bottom = parentElement.clientHeight - offsetValues.top - element.offsetHeight;
    offsetValues.right = parentElement.clientWidth - offsetValues.left - element.offsetWidth;
    return offsetValues;
}
/**
 * Checks if any part of an element is visible in the viewport.
 * @param {Element} element The element to check.
 * @returns {boolean}
 */
export function isElementInViewport(element) {
    if (!isElement(element)) {
        throw new Error('DOMUtils - isElementInViewport: invalid element argument');
    }
    var document = _ownerDocument(element);
    var scrollParentElem = scrollParent(element);
    var elemBCR = element.getBoundingClientRect();
    if (scrollParentElem !== document.documentElement && scrollParentElem !== document.body) {
        var scrollParentOffset = offset(element, scrollParentElem);
        if (scrollParentOffset.top + elemBCR.height < 0 ||
            scrollParentOffset.left + elemBCR.width < 0 ||
            scrollParentOffset.bottom + elemBCR.height - this.scrollbarWidth < 0 ||
            scrollParentOffset.right + elemBCR.width - this.scrollbarWidth < 0) {
            return false;
        }
    }
    if (elemBCR.top + elemBCR.height < 0 ||
        elemBCR.left + elemBCR.width < 0 ||
        elemBCR.bottom + elemBCR.height > document.documentElement.clientHeight ||
        elemBCR.right + elemBCR.width > document.documentElement.clientWidth) {
        return false;
    }
    return true;
}
/**
 * Calculates an elements position relative to another element.  The position
 * returned is relative to the body element.
 * Passing 'auto' as part of the placement parameter
 * will enable smart placement - where the element fits. i.e:
 * 'auto left-top' will check to see if there is enough space to the left
 * of the hostElem to fit the targetElem, if not place right (same for secondary
 * top placement).
 * @param {Element} element The element being positioned.
 * @param {Element} targetElement The element to position to.
 * @param {string} placement The desired placement of the positioned element.
 * @param {Element=} appendToElement The element that the positioned element will be calculated from.
 * Available placements are:
 *   <ul>
 *     <li>top</li>
 *     <li>top-right</li>
 *     <li>top-left</li>
 *     <li>bottom</li>
 *     <li>bottom-left</li>
 *     <li>bottom-right</li>
 *     <li>left</li>
 *     <li>left-top</li>
 *     <li>left-bottom</li>
 *     <li>right</li>
 *     <li>right-top</li>
 *     <li>right-bottom</li>
 *   </ul>
 * @returns {IElementPosition} The position of the element
 */
export function positionElement(element, targetElement, placement, hostElement) {
    if (!isElement(element)) {
        throw new Error('TylDOM - positionElement: invalid element argument');
    }
    if (!isElement(element)) {
        throw new Error('TylDOM - positionElement: invalid target element argument');
    }
    var elemPlacement = parsePlacement(placement);
    var hostElem = hostElement || _ownerDocument(element).body;
    var targetElemOffset = offset(targetElement, hostElem);
    if (elemPlacement.auto) {
        var elemStyle = window.getComputedStyle(element);
        var adjustedSize = {
            width: element.offsetWidth + Math.round(Math.abs(parseStyle('' + elemStyle.marginLeft) + parseStyle('' + elemStyle.marginRight))),
            height: element.offsetHeight + Math.round(Math.abs(parseStyle('' + elemStyle.marginTop) + parseStyle('' + elemStyle.marginBottom)))
        };
        var xOverflow = adjustedSize.width - targetElemOffset.width;
        var yOverflow = adjustedSize.height - targetElemOffset.height;
        switch (elemPlacement.primary) {
            case 'top':
                if (adjustedSize.height > targetElemOffset.top && adjustedSize.height <= targetElemOffset.bottom) {
                    elemPlacement.primary = 'bottom';
                }
                break;
            case 'bottom':
                if (adjustedSize.height > targetElemOffset.bottom && adjustedSize.height <= targetElemOffset.top) {
                    elemPlacement.primary = 'top';
                }
                break;
            case 'left':
                if (adjustedSize.width > targetElemOffset.left && adjustedSize.width <= targetElemOffset.right) {
                    elemPlacement.primary = 'right';
                }
                break;
            case 'right':
                if (adjustedSize.width > targetElemOffset.right && adjustedSize.width <= targetElemOffset.left) {
                    elemPlacement.primary = 'left';
                }
                break;
        }
        switch (elemPlacement.secondary) {
            case 'top':
                if (yOverflow > targetElemOffset.bottom && yOverflow <= targetElemOffset.top) {
                    elemPlacement.secondary = 'bottom';
                }
                break;
            case 'bottom':
                if (yOverflow > targetElemOffset.top && yOverflow <= targetElemOffset.bottom) {
                    elemPlacement.secondary = 'top';
                }
                break;
            case 'left':
                if (xOverflow > targetElemOffset.right && xOverflow <= targetElemOffset.left) {
                    elemPlacement.secondary = 'right';
                }
                break;
            case 'right':
                if (xOverflow > targetElemOffset.left && xOverflow <= targetElemOffset.right) {
                    elemPlacement.secondary = 'left';
                }
                break;
        }
        if (elemPlacement.secondary === 'center') {
            if (REGULAR_EXPRESSIONS.placement.topBottom.test(elemPlacement.primary)) {
                var centerXOverflow = targetElemOffset.width / 2 - element.offsetWidth / 2;
                if (targetElemOffset.left + centerXOverflow < 0 && xOverflow <= targetElemOffset.right) {
                    elemPlacement.secondary = 'left';
                }
                else if (targetElemOffset.right + xOverflow < 0 && xOverflow <= targetElemOffset.left) {
                    elemPlacement.secondary = 'right';
                }
            }
            else {
                var centerYOverflow = targetElemOffset.height / 2 - adjustedSize.height / 2;
                if (targetElemOffset.top + centerYOverflow < 0 && yOverflow <= targetElemOffset.bottom) {
                    elemPlacement.secondary = 'top';
                }
                else if (targetElemOffset.bottom + yOverflow < 0 && yOverflow <= targetElemOffset.top) {
                    elemPlacement.secondary = 'bottom';
                }
            }
        }
    }
    targetElemOffset.top += hostElem.scrollTop;
    targetElemOffset.left += hostElem.scrollLeft;
    targetElemOffset.bottom = hostElem.clientHeight - targetElemOffset.top - targetElemOffset.height;
    targetElemOffset.right = hostElem.clientWidth - targetElemOffset.left - targetElemOffset.width;
    var elemPosition = { top: 0, bottom: 0, left: 0, right: 0, placement: {}, class: '' };
    switch (elemPlacement.primary) {
        case 'top':
            elemPosition.top = targetElemOffset.top - element.offsetHeight;
            elemPosition.bottom = targetElemOffset.bottom + targetElemOffset.height;
            break;
        case 'bottom':
            elemPosition.top = targetElemOffset.top + targetElemOffset.height;
            elemPosition.bottom = targetElemOffset.bottom - element.offsetHeight;
            break;
        case 'left':
            elemPosition.left = targetElemOffset.left - element.offsetWidth;
            elemPosition.right = targetElemOffset.right + targetElemOffset.width;
            break;
        case 'right':
            elemPosition.left = targetElemOffset.left + targetElemOffset.width;
            elemPosition.right = targetElemOffset.right - element.offsetWidth;
            break;
        default:
            break;
    }
    switch (elemPlacement.secondary) {
        case 'top':
            elemPosition.top = targetElemOffset.top;
            elemPosition.bottom = targetElemOffset.bottom + targetElemOffset.height - element.offsetHeight;
            break;
        case 'bottom':
            elemPosition.top = targetElemOffset.top + targetElemOffset.height - element.offsetHeight;
            elemPosition.bottom = targetElemOffset.bottom;
            break;
        case 'left':
            elemPosition.left = targetElemOffset.left;
            elemPosition.right = targetElemOffset.right + targetElemOffset.width - element.offsetWidth;
            break;
        case 'right':
            elemPosition.left = targetElemOffset.left + targetElemOffset.width - element.offsetWidth;
            elemPosition.right = targetElemOffset.right;
            break;
        case 'center':
            if (REGULAR_EXPRESSIONS.placement.topBottom.test(elemPlacement.primary)) {
                elemPosition.left = targetElemOffset.left + targetElemOffset.width / 2 - element.offsetWidth / 2;
                elemPosition.right = targetElemOffset.right + targetElemOffset.width / 2 - element.offsetWidth / 2;
            }
            else {
                elemPosition.top = targetElemOffset.top + targetElemOffset.height / 2 - element.offsetHeight / 2;
                elemPosition.bottom = targetElemOffset.bottom + targetElemOffset.height / 2 - element.offsetHeight / 2;
            }
            break;
        default:
            break;
    }
    elemPosition.placement = elemPlacement;
    elemPosition.top = Math.round(elemPosition.top);
    elemPosition.bottom = Math.round(elemPosition.bottom);
    elemPosition.left = Math.round(elemPosition.left);
    elemPosition.right = Math.round(elemPosition.right);
    elemPosition.class = elemPlacement.secondary === 'center' ? elemPlacement.primary : elemPlacement.primary + '-' + elemPlacement.secondary;
    return elemPosition;
}
/**
 * Adds an event listener to the document that will call the provided callback function
 * when an element and it's children no longer have focus.  The blur and touchstart events are used
 * to evaluate the active element to determine if the callback should be called.
 *
 * @param {Element} element The element to add the event listener to.
 * @param {Function} callback The function to call when the element and children don't have focus.
 * @param {boolean} [delay=false] Should a RAF cycle occur before the callback is called.
 * @returns {Function} The function to call to remove the document events.
 */
export function notChildEventListener(element, callback, delay) {
    var evtHandler = function (event) {
        var handle = function () {
            event.stopPropagation();
            event.preventDefault();
            var activeElement = (event.type === 'touchstart' ? event.target : _ownerDocument(element).activeElement);
            if (!element.contains(activeElement)) {
                callback(activeElement);
            }
        };
        if (delay) {
            window.requestAnimationFrame(function () { return handle(); });
        }
        else {
            handle();
        }
    };
    var docElem = _ownerDocument(element);
    docElem.addEventListener('blur', evtHandler, true);
    docElem.addEventListener('touchstart', evtHandler, true);
    return function () {
        docElem.removeEventListener('blur', evtHandler, true);
        docElem.removeEventListener('touchstart', evtHandler, true);
    };
}
/**
 * Removes all children from a DOM node.
 * @param node The DOM node to remove children from.
 */
export function removeAllChildren(node) {
    while (node.lastChild) {
        node.removeChild(node.lastChild);
    }
}
/**
 * Replaces one child node of the specified node with another.
 * @param newChild The new node to replace `oldChild`.
 * @param oldChild The existing node to be replaced.
 * @returns {Node} The replaced node. Same node as `oldChild`.
 */
export function replaceElement(newChild, oldChild) {
    return oldChild.parentNode.replaceChild(newChild, oldChild);
}
/**
 * Adds a class or array of classes to an element.
 *
 * @param {string | string[]} name The class(es) to add to the element
 * @param {Element} element The element to add class(es) to.
 */
export function addClass(name, element) {
    if (isArray(name)) {
        name.forEach(function (n) { return element.classList.add(n); });
    }
    else {
        element.classList.add(name);
    }
}
/**
 * Removes a class or array of classes to an element.
 *
 * @param {string | string[]} name The class(es) to remove from the element
 * @param {Element} element The element to remove class(es) from.
 */
export function removeClass(name, element) {
    if (isArray(name)) {
        name.forEach(function (n) { return element.classList.remove(n); });
    }
    else {
        element.classList.remove(name);
    }
}
/** Determines which type of animation event is supported. */
export function getAnimationEvent() {
    var el = document.createElement('fakeelement');
    // tslint:disable:object-literal-key-quotes
    var animations = {
        'animation': 'animationend',
        'OAnimation': 'oAnimationEnd',
        'MozAnimation': 'animationend',
        'WebkitAnimation': 'webkitAnimationEnd'
    };
    for (var t in animations) {
        if (el.style[t] !== undefined) {
            return animations[t];
        }
    }
}
/**
 * A helper method to trigger a keyframe animation via adding a class, and removing the class when the animation completes.
 * @param {HTMLElement} element The element to play the animation on.
 * @param {string} className The class to add that triggers the animation.
 */
export function playKeyframeAnimation(element, className) {
    element.classList.add(className);
    var animationEvent = getAnimationEvent();
    var animationCompletedListener = function () {
        element.classList.remove(className);
        element.removeEventListener(animationEvent, animationCompletedListener);
    };
    element.addEventListener(animationEvent, animationCompletedListener);
}
/**
 * Removes an element from the DOM using the available remove method for that platform.
 * @param {HTMLElement} element The element to remove.
 */
export function removeElement(element) {
    if (element['removeNode']) {
        element['removeNode'](true);
    }
    else if (element['remove']) {
        element.remove();
    }
    else {
        element.parentNode.removeChild(element);
    }
}
/**
 * Returns a width string that is safe for css based on the provided input.
 * @param {string | number} width
 * @returns {string | undefined} A width safe for using in CSS.
 */
export function safeCssWidth(width) {
    if (typeof width === 'string') {
        if (width[width.length - 1] === '%') {
            return width;
        }
        else if (width.slice(-2) === 'px') {
            return width;
        }
        else if (Number(width) >= 0) {
            return width + "px";
        }
    }
    else if (typeof width === 'number') {
        if (width >= 0) {
            return width + "px";
        }
    }
    return undefined;
}
/**
 * Calculates the size of an element that is not attached to the DOM.
 * @param {HTMLElement} element The element to calc the size of.
 * @returns {width, height} The size of the element.
 */
export function calcSizeUnattached(element) {
    var container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.top = '-99999px';
    container.style.left = '-99999px';
    container.style.visibility = 'hidden';
    container.appendChild(element.cloneNode(true));
    document.body.appendChild(container);
    var size = {
        width: container.scrollWidth,
        height: container.scrollHeight
    };
    removeElement(container);
    container = undefined;
    return size;
}
/**
 * Resolves a promise when the provided element has children.
 * @param {Element} element An element that does or will contain children.
 */
export function ensureChildren(element) {
    if (element.children.length) {
        return Promise.resolve();
    }
    return new Promise(function (resolve) {
        var observer = new MutationObserver(function (changes) {
            if (element.children.length) {
                observer.disconnect();
                resolve();
            }
        });
        observer.observe(element, { childList: true });
    });
}
/**
 * Resolves a promise when the provided element has a child that matches a given selector.
 * @param {Element} element An element that does or will contain children.
 * @param {string} selector A CSS selector to use for finding an element.
 */
export function ensureChild(element, selector) {
    var initialElements = deepQuerySelectorAll(element, selector);
    if (initialElements.length) {
        return Promise.resolve(initialElements[0]);
    }
    return new Promise(function (resolve) {
        var observer = new MutationObserver(function (changes) {
            var hasAddedNodes = changes.reduce(function (prev, curr) { return prev + curr.addedNodes.length; }, 0) > 0;
            if (hasAddedNodes) {
                var foundElements = deepQuerySelectorAll(element, selector);
                if (foundElements.length) {
                    observer.disconnect();
                    resolve(foundElements[0]);
                }
            }
        });
        observer.observe(element, { childList: true, subtree: true });
    });
}
/**
 * Walks up the tree starting a specific node and stops when the provided matcher function returns true.
 * @param {Node} node The node to start searching from.
 * @returns {Node | null} The closest matching ancestor node, or null if not found.
 */
export function walkUpUntil(node, matcher) {
    var parent = (node && node.parentNode);
    while (parent) {
        if (matcher(parent)) {
            return parent;
        }
        parent = parent.parentNode;
    }
    return null;
}
/**
 * Calculates the width of a string given the provided font information.
 */
export function calculateFontWidth(value, info) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var fontSize = info ? info.fontSize : 16;
    var fontFamily = info ? info.fontFamily : 'Roboto';
    ctx.font = fontSize + "px " + fontFamily;
    return ctx.measureText(value).width;
}
/**
 * Generates a CSS text-shadow style value based on the number of iterations and color provided.
 * @param {number} iterations The number of iterations for how long the shadow should be.
 * @param {string} color The color of the text shadow. Can be any CSS-safe color format. Ex. hex, rgb, rgba, hsl... etc.
 */
export function generateTextShadow(iterations, color) {
    var shadows = [];
    for (var i = 1; i <= iterations; i++) {
        shadows.push(i + "px " + i + "px " + color);
    }
    return shadows.join(', ');
}
