/**
 * Represents a single message list entry containing a string message and its identifier.
 */
var MessageListEntry = /** @class */ (function () {
    function MessageListEntry(message, identifier) {
        this.message = message;
        this.identifier = identifier;
        this.originalMessage = message;
    }
    return MessageListEntry;
}());
export { MessageListEntry };
