import { MessageListEntry } from './message-list-entry';
/**
 * Represents a message list of any type. This class can be used to attach a string message to
 * a generic identifier.
 */
var MessageList = /** @class */ (function () {
    function MessageList(useAutoEllipsis) {
        if (useAutoEllipsis === void 0) { useAutoEllipsis = true; }
        this._useAutoEllipsis = true;
        this._useAutoEllipsis = useAutoEllipsis;
        this.reset();
    }
    Object.defineProperty(MessageList.prototype, "useAutoEllipsis", {
        /**
         * True if ellipsis management occurs automatically. Default is true.
         */
        get: function () {
            return this._useAutoEllipsis;
        },
        set: function (value) {
            this._useAutoEllipsis = value;
            this._updateMessage();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds a message to the entry map.
     * @param {string} message The message string.
     * @param {T} identifier The message identifier.
     * @returns {MessageList<T>} A reference to `this` for chaining.
     */
    MessageList.prototype.add = function (message, identifier) {
        this._messages.push(new MessageListEntry(message, identifier));
        this._updateMessage();
        return this;
    };
    /**
     * Removes a message using the provided identifier.
     * @param {T} identifier The message identifier.
     * @returns {MessageList<T>} A reference to `this` for chaining.
     */
    MessageList.prototype.remove = function (identifier) {
        var index = this._getMessageIndex(identifier);
        if (index >= 0) {
            this._messages.splice(index, 1);
            this._updateMessage();
        }
        return this;
    };
    /**
     * Updates an existing message value.
     * @param {string} message The message string.
     * @param {T} identifier The existing message identifier.
     * @returns {MessageList<T>} A reference to `this` for chaining.
     */
    MessageList.prototype.update = function (message, identifier) {
        var index = this._getMessageIndex(identifier);
        if (index >= 0) {
            this._messages[index].message = message;
            this._updateMessage();
        }
        return this;
    };
    Object.defineProperty(MessageList.prototype, "messageCount", {
        /**
         * Gets the number of messages in the list.
         * @returns {number}
         */
        get: function () {
            return this._messages.length;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Resets the message list to it's original state and removes all messages.
     */
    MessageList.prototype.reset = function () {
        this._messages = [];
        this.message = '';
    };
    /**
     * Determines if a message with the provided identifier exists.
     * @param {T} identifier The message identifier.
     */
    MessageList.prototype.hasMessage = function (identifier) {
        return this._getMessageIndex(identifier) >= 0;
    };
    MessageList.prototype._updateMessage = function () {
        var _this = this;
        this.message = '';
        this._messages.forEach(function (item, index) {
            if (_this.useAutoEllipsis) {
                var hasEllipsis = item.message.substr(item.message.length - 3) === '...';
                if (index === _this._messages.length - 1) {
                    if (!hasEllipsis) {
                        item.message += '...';
                    }
                }
                else if (hasEllipsis) {
                    item.message = item.message.substr(0, item.message.length - 3);
                }
            }
            else {
                item.message = item.originalMessage;
            }
            _this.message += item.message + (index < _this._messages.length - 1 ? ', ' : '');
        });
        return this;
    };
    MessageList.prototype._getMessageIndex = function (identifier) {
        var index = -1;
        for (var i = 0; i < this._messages.length; i++) {
            if (this._messages[i].identifier === identifier) {
                index = i;
                break;
            }
        }
        return index;
    };
    return MessageList;
}());
export { MessageList };
