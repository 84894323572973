var EventAware = /** @class */ (function () {
    function EventAware() {
        this._listenerMap = new Map();
    }
    EventAware.prototype._emit = function (type, data) {
        var listeners = this._listenerMap.get(type);
        if (listeners && listeners.length) {
            listeners.forEach(function (cb) { return cb({ type: type, data: data }); });
        }
    };
    EventAware.prototype.hasListeners = function (type) {
        return type ? this._listenerMap.has(type) : this._listenerMap.size > 0;
    };
    EventAware.prototype.addListener = function (type, listener) {
        if (!this._listenerMap.has(type)) {
            this._listenerMap.set(type, [listener]);
        }
        else {
            var listeners = this._listenerMap.get(type);
            if (listeners) {
                listeners.push(listener);
            }
        }
    };
    EventAware.prototype.removeListener = function (type, listener) {
        var listeners = this._listenerMap.get(type);
        if (listeners && listeners.length && listeners.includes(listener)) {
            listeners.splice(listeners.indexOf(listener), 1);
            if (!listeners.length) {
                this._listenerMap.delete(type);
            }
        }
    };
    return EventAware;
}());
export { EventAware };
